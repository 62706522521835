@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,600,600i&display=swap");
figure figcaption,body,button,input,select,textarea,h1,h2,h3,h4,h5,h6,blockquote cite,blockquote p cite{font-family:"Source Sans Pro",Helvetica,Arial,sans-serif;font-display:swap}
.header__intro p,blockquote,blockquote p{font-family:expo-serif-pro,"Palatino Linotype",Georgia,serif;font-display:swap}
input[type='submit']::before,input[type='submit']::after,button::before,button::after,a::before,a::after,.listings__filters-category .filters-label::after,.listings__filters-search-box::before,.listings__filters-category_list label::before{font-family:"careerportal";font-display:swap}
.listings__filters-category .filters-label{font-size:16px;font-size:1rem;color:#666}
figure figcaption{color:#666;font-size:16px;font-size:1rem;line-height:1.25}
input[type='submit']:focus,button:focus,input[type='checkbox']:focus+label,input[type='checkbox']:focus+span.label,input[type='search']:focus,a:focus,.post-footer button:focus::after,.share .icon:focus,.site-footer__details-social .icon:focus{outline:2px solid #54b7f4}
input.clear-all[type='submit'],button.clear-all,a.menu-item__link{color:#666}
input.clear-all:hover[type='submit'],button.clear-all:hover,a.menu-item__link:hover,input.clear-all:focus[type='submit'],button.clear-all:focus,a.menu-item__link:focus{color:#000}
main a.dark:not(.icon):not(.btn),.site-footer a.dark:not(.icon):not(.btn){color:#d4efff;background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0, #d4efff), to(#d4efff));background-image:linear-gradient(to bottom, #d4efff 0, #d4efff 100%)}
main a.dark:not(.btn):not(.icon)::before,.site-footer a.dark:not(.btn):not(.icon)::before,main a.dark:not(.btn):not(.icon)::after,.site-footer a.dark:not(.btn):not(.icon)::after{color:#d4efff}
main a.dark:hover:not(.icon):not(.btn),.site-footer a.dark:hover:not(.icon):not(.btn),main a.dark:focus:not(.icon):not(.btn),.site-footer a.dark:focus:not(.icon):not(.btn){color:#fff}
main a.dark:not(.btn):not(.icon):hover::before,.site-footer a.dark:not(.btn):not(.icon):hover::before,main a.dark:not(.btn):not(.icon):hover::after,.site-footer a.dark:not(.btn):not(.icon):hover::after,main a.dark:not(.btn):not(.icon):focus::before,.site-footer a.dark:not(.btn):not(.icon):focus::before,main a.dark:not(.btn):not(.icon):focus::after,.site-footer a.dark:not(.btn):not(.icon):focus::after{color:#fff}
main a:not(.icon):not(.dark),.site-footer a:not(.icon):not(.dark){color:#0065a4;background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0, #0065a4), to(#0065a4));background-image:linear-gradient(to bottom, #0065a4 0, #0065a4 100%)}
main a:hover:not(.icon):not(.dark),.site-footer a:hover:not(.icon):not(.dark),main a:focus:not(.icon):not(.dark),.site-footer a:focus:not(.icon):not(.dark){color:#00497a}
main a:not(.dark):not(.icon):hover::before,.site-footer a:not(.dark):not(.icon):hover::before,main a:not(.dark):not(.icon):hover::after,.site-footer a:not(.dark):not(.icon):hover::after,main a:not(.dark):not(.icon):focus::before,.site-footer a:not(.dark):not(.icon):focus::before,main a:not(.dark):not(.icon):focus::after,.site-footer a:not(.dark):not(.icon):focus::after{color:#00497a}
main a:not(.dark):not(.icon)::before,.site-footer a:not(.dark):not(.icon)::before,main a:not(.dark):not(.icon)::after,.site-footer a:not(.dark):not(.icon)::after{color:#0065a4}
input[type='submit']::before,input[type='submit']::after,button::before,button::after,a::before,a::after,.listings__filters-category .filters-label::after,.listings__filters-search-box::before,.listings__filters-category_list label::before{position:relative;top:-0.05rem;display:inline-block;font-weight:normal;line-height:1em;text-align:center;-webkit-transition:all 0.3s ease-in-out;transition:all 0.3s ease-in-out;speak:none;-webkit-font-smoothing:antialiased;font-size:14px;font-size:.875rem}
@media screen and (min-width: 0) and (max-width: 48em){#header figure.component-image,.post-footer{margin-right:-20px;margin-left:-20px}}
@media screen and (min-width: 48em) and (max-width: 63em){#header figure.component-image,.post-footer{margin-right:-4vw;margin-left:-4vw}}
@media screen and (min-width: 0){.site-footer::before,section.row:not(#disclaimer):not(#job_alerts):not(#testimonials):not(#intern_eligibility):not(:first-of-type)::before{margin:0 -20px}}
@media screen and (min-width: 48em){.site-footer::before,section.row:not(#disclaimer):not(#job_alerts):not(#testimonials):not(#intern_eligibility):not(:first-of-type)::before{margin:0 -4vw}}
@media screen and (min-width: 63em){.site-footer::before,section.row:not(#disclaimer):not(#job_alerts):not(#testimonials):not(#intern_eligibility):not(:first-of-type)::before{margin:0 -4vw}}
@media screen and (min-width: 90em){.site-footer::before,section.row:not(#disclaimer):not(#job_alerts):not(#testimonials):not(#intern_eligibility):not(:first-of-type)::before{margin:0 -2vw}}
html{-webkit-box-sizing:border-box;box-sizing:border-box;overflow-x:hidden}
*,*::before,*::after{-webkit-box-sizing:inherit;box-sizing:inherit}
body{--breakpoint: 'xsmall';position:relative;margin:0;overflow-x:hidden;background:#fff}
@media screen and (min-width: 0){body{--breakpoint: 'small'}}
@media screen and (min-width: 48em){body{--breakpoint: 'medium'}}
@media screen and (min-width: 63em){body{--breakpoint: 'large'}}
@media screen and (min-width: 90em){body{--breakpoint: 'xlarge'}}
@media screen and (min-width: 120em){body{--breakpoint: 'xlarge-2'}}
nav,main,footer{max-width:1400px;margin:auto}
@media screen and (min-width: 0){nav,main,footer{width:calc(100vw - 20px * 2)}}
@media screen and (min-width: 48em){nav,main,footer{width:calc(100vw - 4vw * 2)}}
@media screen and (min-width: 63em){nav,main,footer{width:calc(100vw - 4vw * 2)}}
@media screen and (min-width: 90em){nav,main,footer{width:calc(100vw - 2vw * 2)}}
main,footer{position:relative;z-index:-100}
nav,main,footer,header{display:block}
a,a::before,i,i::before,button,button::before{-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out}
.wrapper{position:relative;z-index:1}
.js-no-scroll,.js-no-scroll body{height:unset !important;overflow:hidden;-ms-touch-action:none;touch-action:none}
.allow-scroll{-ms-touch-action:auto !important;touch-action:auto !important}
.content-overlay{position:absolute;top:0;right:0;bottom:0;left:0;z-index:-10;background-color:#000;visibility:hidden;opacity:0;-webkit-transition:all 0.3s ease-in-out;transition:all 0.3s ease-in-out}
.content-overlay.is-active{visibility:visible;opacity:0.25}
.js-transition--slide.js-transition--slide-root{-webkit-transform:translateX(50px);transform:translateX(50px);visibility:hidden;opacity:0;-webkit-transition:all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);transition:all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)}
.js-transition--slide.js-transition--slide-root.js-transition--active{-webkit-transform:none;transform:none;visibility:visible;opacity:1}
.js-transition--slide.js-transition--slide-child{visibility:hidden;opacity:0;-webkit-transition:all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);transition:all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)}
.js-transition--slide.js-transition--slide-child>*{-webkit-transform:translateX(50px);transform:translateX(50px);-webkit-transition:all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94);transition:all 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)}
.js-transition--slide.js-transition--slide-child>*.post-block__header{-webkit-transition-delay:0.15s;transition-delay:0.15s;-webkit-transition-duration:0.8s;transition-duration:0.8s}
.js-transition--slide.js-transition--slide-child>*.post-block__content,.js-transition--slide.js-transition--slide-child>*.post-block__footer{-webkit-transition-delay:0.3s;transition-delay:0.3s;-webkit-transition-duration:0.8s;transition-duration:0.8s}
.js-transition--slide.js-transition--slide-child.js-transition--active{visibility:visible;opacity:1}
.js-transition--slide.js-transition--slide-child.js-transition--active>*{-webkit-transform:none;transform:none}
.visually-hidden{position:absolute;top:auto;left:-10000px;width:1px;height:1px;overflow:hidden}
.focusable.visually-hidden:focus{position:relative;left:0;width:auto;height:auto;overflow:auto}
@media print{*,*::before,*::after{color:#000 !important;text-shadow:none !important;background:transparent !important;-webkit-box-shadow:none !important;box-shadow:none !important;-webkit-filter:none !important;filter:none !important}body{margin:25mm}body::before{display:block;font-family:expo-serif-pro,"Palatino Linotype",Georgia,serif;font-size:75pt;text-align:center;content:'CSIS | Careers'}h2,h3{page-break-after:avoid;-webkit-column-break-after:avoid;-moz-column-break-after:avoid;break-after:avoid}.site-header,.listings__filters,.share,.component-image,.post-footer,.csis-logo,.Page-logo,.apply,.forward,.backward,.site-footer__subsection section:first-of-type,.site-footer__details-social{display:none !important}a:link,a:visited,.site-footer li{color:#000 !important}.post .job-content__body,.job .job-content__body{padding:5mm}.post .job-meta,.job .job-meta{-ms-grid-columns:auto auto;grid-template-columns:auto auto;-ms-grid-rows:1fr auto;grid-template-rows:1fr auto}.post .job-meta > *:nth-child(1), .job .job-meta > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1}.post .job-meta > *:nth-child(2), .job .job-meta > *:nth-child(2){-ms-grid-row:1;-ms-grid-column:2}.post .job-meta > *:nth-child(3), .job .job-meta > *:nth-child(3){-ms-grid-row:2;-ms-grid-column:1}.post .job-meta > *:nth-child(4), .job .job-meta > *:nth-child(4){-ms-grid-row:2;-ms-grid-column:2}}
body,button,input,select,textarea{color:#444}
*{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
h1{margin:0 0 0.5rem;color:#000;font-weight:600;line-height:1.2;-webkit-font-smoothing:antialiased}
h2{margin:0 0 0.5rem;color:#000;font-weight:600;line-height:1.2;-webkit-font-smoothing:antialiased}
h3{margin:0 0 0.5rem;color:#000;font-weight:600;line-height:1.2;-webkit-font-smoothing:antialiased}
h4{margin:0 0 0.5rem;color:#000;font-weight:600;line-height:1.2;-webkit-font-smoothing:antialiased}
h5{margin:0 0 0.5rem;color:#000;font-weight:600;line-height:1.2;-webkit-font-smoothing:antialiased}
h6{margin:0 0 0.5rem;color:#000;font-weight:600;line-height:1.2;-webkit-font-smoothing:antialiased}
h1{line-height:1.06;font-size:calc( 36px + 17 * ((100vw - 420px) / 480))}
@media screen and (max-width: 420px){h1{font-size:36px;font-size:2.25rem}}
@media screen and (min-width: 900px){h1{font-size:53px;font-size:3.3125rem}}
h2{font-size:calc( 32px + 3.2 * ((100vw - 420px) / 480));margin-bottom:0.75rem}
@media screen and (max-width: 420px){h2{font-size:32px;font-size:2rem}}
@media screen and (min-width: 900px){h2{font-size:35.2px;font-size:2.2rem}}
h3{font-size:calc( 24px + 2 * ((100vw - 420px) / 480))}
@media screen and (max-width: 420px){h3{font-size:24px;font-size:1.5rem}}
@media screen and (min-width: 900px){h3{font-size:26px;font-size:1.625rem}}
h4{font-size:calc( 20px + 2 * ((100vw - 420px) / 480))}
@media screen and (max-width: 420px){h4{font-size:20px;font-size:1.25rem}}
@media screen and (min-width: 900px){h4{font-size:22px;font-size:1.375rem}}
.faq h1{margin-bottom:2rem}
section p{font-size:18px;font-size:1.125rem;margin:0.25rem 0 1rem;line-height:1.56}
.header__intro p{font-size:calc( 18px + 2 * ((100vw - 420px) / 480));margin-bottom:2rem}
@media screen and (max-width: 420px){.header__intro p{font-size:18px;font-size:1.125rem}}
@media screen and (min-width: 900px){.header__intro p{font-size:20px;font-size:1.25rem}}
ul{margin-top:0;margin-bottom:24px;padding-left:0;list-style-type:none}
ul:last-of-type{margin-bottom:0}
section ul{font-size:18px;font-size:1.125rem}
ul li:not(.job){margin-bottom:0.5rem}
.cantilever ul,.column ul{padding-left:2rem;list-style-type:disc}
.cantilever ul li,.column ul li{padding-left:1rem;line-height:1.33}
strong{font-weight:600}
em{font-style:italic}
blockquote{margin:0 0 0.5rem}
blockquote,blockquote p{color:#000;line-height:1.5;font-size:20px;font-size:1.25rem}
blockquote cite,blockquote p cite{color:#666;font-style:normal;font-size:16px;font-size:1rem}
address{margin:0 0 0.75rem;font-style:normal}
.sidebar__text p{font-size:16px;font-size:1rem}
input[type='submit'],button{padding:0;text-transform:uppercase;background-color:transparent;border:0;cursor:pointer;font-size:15px;font-size:.9375rem}
input[type='submit'].block,button.block{display:block}
input[type='submit'].clear-all,button.clear-all{font-weight:bold}
input[type='submit']::after,button::after{margin-left:0.25rem}
input[type='submit']::before,button::before{margin-right:0.25rem}
input[type='submit'].btn.dark,button.btn.dark{padding:0.5rem;color:#fff;background-color:#0065a4;border-radius:3px}
input[type='submit'].btn.dark:hover,input[type='submit'].btn.dark:focus,button.btn.dark:hover,button.btn.dark:focus{color:#fff;background-color:#00497a}
input[type='checkbox']{position:absolute;opacity:0}
input[type='search']{background-color:transparent;border:0;font-size:18px;font-size:1.125rem}
input[type='search']::-webkit-search-decoration,input[type='search']::-webkit-search-cancel-button,input[type='search']::-webkit-search-results-button,input[type='search']::-webkit-search-results-decoration{-webkit-appearance:none}
input[type='search']::-webkit-input-placeholder{color:#999}
input[type='search']::-moz-placeholder{color:#999}
input[type='search']:-ms-input-placeholder{color:#999}
input[type='search']::-ms-input-placeholder{color:#999}
input[type='search']::placeholder{color:#999}
.site-header__nav{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:100%}
.site-header__nav-trigger{position:relative;display:block;width:24px;height:24px;padding:0.125rem}
@media screen and (min-width: 63em){.site-header__nav-trigger{display:none}}
.site-header--hidden .site-header__nav-trigger{display:none}
.site-header__nav-trigger .line{position:absolute;left:50%;display:block;width:100%;max-width:18px;height:2px;background:#000;border-radius:9px;-webkit-transform:translateX(-50%) rotate(0deg);transform:translateX(-50%) rotate(0deg);-webkit-transform-origin:-5%;transform-origin:-5%;-webkit-transition:0.25s ease-in-out;transition:0.25s ease-in-out}
.site-header__nav-trigger .line:nth-child(1){top:15%}
.site-header__nav-trigger .line:nth-child(2){top:45%}
.site-header__nav-trigger .line:nth-child(3){top:75%}
.site-header__nav-trigger:hover .line,.site-header__nav-trigger:focus .line{background-color:#4793c2}
.site-header__nav-trigger.is-active .line:nth-child(2){top:50%;left:25%;width:0%}
.site-header__nav-trigger.is-active .line:nth-child(1){-webkit-transform:translateX(-33%) rotate(45deg);transform:translateX(-33%) rotate(45deg)}
.site-header__nav-trigger.is-active .line:nth-child(3){-webkit-transform:translateX(-33%) rotate(-45deg);transform:translateX(-33%) rotate(-45deg)}
.site-header__nav-trigger.is-active:hover .line{background-color:#4793c2}
.site-header__nav-menu{margin:0;padding:0;-webkit-transition:opacity 0s linear 0.3s, -webkit-transform 0.5s linear;transition:opacity 0s linear 0.3s, -webkit-transform 0.5s linear;transition:transform 0.5s linear, opacity 0s linear 0.3s;transition:transform 0.5s linear, opacity 0s linear 0.3s, -webkit-transform 0.5s linear}
@media screen and (max-width: 63em){.site-header__nav-menu{position:absolute;top:100%;left:0;z-index:-1;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;background-color:#fff;-webkit-transform:translateY(-150%);transform:translateY(-150%);opacity:0}}
@media screen and (min-width: 63em){.site-header__nav-menu{display:-ms-grid;display:grid;grid-column-gap:2.5rem;-ms-grid-columns:auto 2.5rem auto 2.5rem auto;grid-template-columns:repeat(3, auto);-ms-grid-rows:1fr;grid-template-rows:1fr}.site-header__nav-menu > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1}.site-header__nav-menu > *:nth-child(2){-ms-grid-row:1;-ms-grid-column:3}.site-header__nav-menu > *:nth-child(3){-ms-grid-row:1;-ms-grid-column:5}}
.site-header__nav-menu.is-active{-webkit-transform:translateY(2px);transform:translateY(2px);opacity:1}
.site-header__nav-menu .menu-item{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:0}
@media screen and (max-width: 63em){.site-header__nav-menu .menu-item{border-bottom:1px solid #666}}
.application__container{height:100%;height:1600px;overflow:hidden}
.application__container #application{width:100%;height:1800px;margin-top:-165px;margin-left:-20px}
.job-agent__container{height:100%;height:775px;overflow:hidden}
.job-agent__container #job-agent{width:100%;height:1000px;margin-top:-120px;overflow:hidden}
.job__location{font-size:16px;font-size:1rem;font-style:italic;line-height:1.5}
.job-meta{font-size:16px;font-size:1rem;display:-ms-inline-grid;display:inline-grid;grid-column-gap:24px;-ms-grid-columns:1fr;grid-template-columns:1fr;-ms-grid-rows:1fr auto;grid-template-rows:1fr auto;line-height:1.5}
.job-meta > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1}
.job-meta > *:nth-child(2){-ms-grid-row:2;-ms-grid-column:1}
@media screen and (min-width: 48em) and (max-width: 63em){.job-meta{-ms-grid-columns:auto auto;grid-template-columns:auto auto;-ms-grid-rows:1fr auto;grid-template-rows:1fr auto}.job-meta > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1}.job-meta > *:nth-child(2){-ms-grid-row:1;-ms-grid-column:2}.job-meta > *:nth-child(3){-ms-grid-row:2;-ms-grid-column:1}.job-meta > *:nth-child(4){-ms-grid-row:2;-ms-grid-column:2}}
@media screen and (min-width: 63em){.job-meta{-ms-grid-columns:auto auto auto auto;grid-template-columns:auto auto auto auto;-ms-grid-rows:1fr auto;grid-template-rows:1fr auto}.job-meta > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1}.job-meta > *:nth-child(2){-ms-grid-row:1;-ms-grid-column:2}.job-meta > *:nth-child(3){-ms-grid-row:1;-ms-grid-column:3}.job-meta > *:nth-child(4){-ms-grid-row:1;-ms-grid-column:4}.job-meta > *:nth-child(5){-ms-grid-row:2;-ms-grid-column:1}.job-meta > *:nth-child(6){-ms-grid-row:2;-ms-grid-column:2}.job-meta > *:nth-child(7){-ms-grid-row:2;-ms-grid-column:3}.job-meta > *:nth-child(8){-ms-grid-row:2;-ms-grid-column:4}}
.job-meta__label{font-weight:bold}
.job__details{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;white-space:nowrap}
@media screen and (min-width: 90em){.job__details{-ms-flex-wrap:nowrap;flex-wrap:nowrap}}
@media screen and (min-width: 63em) and (max-width: 90em){.job__details>*:last-child{-ms-flex-preferred-size:100%;flex-basis:100%}}
@media screen and (min-width: 90em){.job__details>*:last-child{-ms-flex-preferred-size:auto;flex-basis:auto}}
@media screen and (min-width: 90em){.job-header .job__details{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center}}
a{text-decoration:none;background-repeat:repeat-x;background-position:0 87.5%}
a:focus{padding:0 0.125rem;background-position:0 87.5%}
a.block{display:block}
#about a.block.external{display:table;margin:1rem auto 2rem 0;font-weight:600}
@media screen and (min-width: 48em){#about a.block.external{margin:1rem 0 2rem auto}}
a.backward{display:block;padding-bottom:0.5rem;text-transform:uppercase}
a.forward{text-transform:uppercase}
@media screen and (min-width: 63em){a.profile{padding:0.5rem;color:#fff;background-color:#0065a4;border-radius:3px;padding:0.75rem}a.profile:hover,a.profile:focus{color:#fff;background-color:#00497a}}
a.apply{display:inline-block;min-width:180px;padding:0.75rem;font-weight:bold;text-align:center;text-transform:uppercase;padding:0.5rem;color:#fff;background-color:#0065a4;border-radius:3px;font-size:15px;font-size:.9375rem}
a.apply:hover,a.apply:focus{color:#fff;background-color:#00497a}
@media screen and (max-width: 48em){.job-content__body a.apply{display:none}}
.job-content__about a.apply{margin-bottom:3rem}
a.large{font-weight:600;font-size:17px;font-size:1.0625rem}
a.large::after,a.large::before{font-size:15px;font-size:.9375rem}
.job__title>a.large{font-size:22px;font-size:1.375rem}
a::after{margin-left:0.25rem}
a::before{margin-right:0.25rem}
main #listings a:not(.icon):not(.dark):visited{color:#4793c2}
main a:not(.icon),.site-footer a:not(.icon){background-size:1px 1px}
main a:not(.icon):hover,main a:not(.icon):focus,.site-footer a:not(.icon):hover,.site-footer a:not(.icon):focus{background-size:1px 0}
main a:not(.icon)[class],.site-footer a:not(.icon)[class]{background-size:1px 0}
main a:not(.icon)[class].external:hover,main a:not(.icon)[class].external:focus,.site-footer a:not(.icon)[class].external:hover,.site-footer a:not(.icon)[class].external:focus{background-size:1px 1px}
a.profile::before{content:'\e80d'}
a.external::after{content:'\e802'}
a.backward::before{content:'\e805'}
a.forward::after{content:'\e807'}
a.menu-item__link{width:100%;font-weight:bold;text-align:left;text-transform:uppercase;font-size:16px;font-size:1rem}
@media screen and (max-width: 63em){a.menu-item__link{padding:1.75rem;border-left:6px solid transparent}a.menu-item__link:hover,a.menu-item__link:focus{border-left:6px solid #0065a4}}
@media screen and (min-width: 63em){a.menu-item__link{text-align:center}}
@media screen and (min-width: 63em){a.menu-item__link:not(.profile){padding:0.25rem 0.5rem}}
img{max-width:100%}
main img{width:100%}
@media screen and (min-width: 63em){figure.component-image{margin-top:0.5rem}}
figure.component-image .component-image__container{width:calc(100% + 4vw)}
@media screen and (max-width: 63em){#header figure.component-image{margin-bottom:2.5rem}}
#header figure.component-image__container{position:relative;height:0;padding-bottom:67%;overflow:hidden}
#header figure.component-image__container img{position:absolute;left:50%;width:auto;max-width:none;height:100%;-webkit-transform:translateX(-50%);transform:translateX(-50%)}
@media screen and (min-width: 48em){section:not(#header) figure.component-image{margin-right:-4vw}}
#deadlines figure.component-image{margin-top:2rem}
figure.component-image figcaption.img-caption{margin-top:0.5rem}
iframe{display:block;margin-bottom:1rem}
.video-wrapper{position:relative;margin-bottom:2rem}
.video-wrapper::before{content:'';display:block;padding-bottom:calc(100% / (16 / 9))}
*+.video-wrapper{margin-top:2rem}
.video-wrapper>img{height:auto}
.video-wrapper>:first-child{position:absolute;top:0;left:0;width:100%;height:100%}
figure{margin:0}
figure figcaption{max-width:calc( 100vw - 40px);margin:auto}
@media screen and (min-width: 48em) and (max-width: 63em){figure figcaption{max-width:calc( 100vw - 8vw)}}
.post-footer{position:fixed;bottom:0;z-index:10;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100vw;min-height:50px;background-color:#fff;border-top:1px solid #d3d3d3}
@media screen and (min-width: 0){.post-footer{padding:0 20px 0 0}}
@media screen and (min-width: 48em){.post-footer{padding:0 4vw 0 0}}
@media screen and (min-width: 63em){.post-footer{padding:0 4vw 0 0}}
@media screen and (min-width: 90em){.post-footer{padding:0 2vw 0 0}}
@media screen and (min-width: 63em){.post-footer{display:none}}
.post-footer button{font-size:0.67rem}
.post-footer button:focus{outline:none}
.post-footer button::after{padding:0.5rem;color:#fff;background-color:#0065a4;border-radius:3px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0;font-weight:bold;border-radius:50%}
.post-footer button::after:hover,.post-footer button::after:focus{color:#fff;background-color:#00497a}
@media screen and (max-width: 20em){.post-footer button::after{font-size:11px;font-size:.6875rem;width:1.6rem;height:1.6rem}}
@media screen and (min-width: 20.1em){.post-footer button::after{font-size:14px;font-size:.875rem;width:2rem;height:2rem}}
.post-footer .btn-share.menu-trigger{background-color:#fff}
@media screen and (min-width: 0){.post-footer .btn-share.menu-trigger{width:calc(2rem + 20px);padding:0 0 0 20px}}
@media screen and (min-width: 48em){.post-footer .btn-share.menu-trigger{width:calc(2rem + 4vw);padding:0 0 0 4vw}}
@media screen and (min-width: 63em){.post-footer .btn-share.menu-trigger{width:calc(2rem + 4vw);padding:0 0 0 4vw}}
@media screen and (min-width: 90em){.post-footer .btn-share.menu-trigger{width:calc(2rem + 2vw);padding:0 0 0 2vw}}
.post-footer .btn-share.menu-trigger::after{content:'\e803'}
.post-footer .btn-back-to-top{padding:0}
.post-footer .btn-back-to-top::after{content:'\e810'}
.post-footer__nav-menu{position:absolute;left:0;z-index:-1;display:-webkit-box;display:-ms-flexbox;display:flex;width:calc(100% - 8rem);margin:0;padding:0;background-color:#fff;-webkit-transform:translateX(-150%);transform:translateX(-150%);opacity:0;-webkit-transition:opacity 0s linear 0.3s, -webkit-transform 0.5s linear;transition:opacity 0s linear 0.3s, -webkit-transform 0.5s linear;transition:transform 0.5s linear, opacity 0s linear 0.3s;transition:transform 0.5s linear, opacity 0s linear 0.3s, -webkit-transform 0.5s linear}
.post-footer__nav-menu.is-active{opacity:1}
@media screen and (max-width: 20em){.post-footer__nav-menu.is-active{-webkit-transform:translateX(17.5vw);transform:translateX(17.5vw)}}
@media screen and (min-width: 20.1em){.post-footer__nav-menu.is-active{-webkit-transform:translateX(16.5vw);transform:translateX(16.5vw)}}
.share{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
@media screen and (max-width: 63em){.job-header .share{display:none}}
@media screen and (max-width: 90em){.job-header .share{margin-top:1rem}}
.share ul{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0;padding:0;list-style-type:none}
.share ul li{margin:0}
.share .icon{display:block;width:1.75rem;height:1.75rem;margin:0 0 0 0.5rem;border:1px solid #4793c2;border-radius:50%;cursor:pointer;font-size:20px;font-size:1.25rem}
@media screen and (max-width: 20em){.post-footer .share .icon{width:1.6rem;height:1.6rem}}
@media screen and (min-width: 20.1em){.post-footer .share .icon{width:2rem;height:2rem}}
.share .icon:hover{background-color:#0065a4}
.share .icon:hover::before{color:#fff}
.share .icon:focus{padding:0 0.125rem;background-position:0 87.5%}
.share .icon::before{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;height:100%;margin:0;color:#0065a4}
.share .icon.icon-email::before{font-size:0.67rem}
.share .icon.icon-printer::before,.share .icon.icon-copy-link::before{font-size:0.825rem}
.share .list-item__copy{position:relative;display:inline-block}
.share .list-item__copy .copy-text{position:absolute;bottom:150%;left:50%;z-index:1;width:140px;margin-left:-75px;padding:5px;color:#fff;text-align:center;background-color:#555;border-radius:6px;visibility:hidden;opacity:0;-webkit-transition:opacity 0.3s;transition:opacity 0.3s}
.share .list-item__copy .copy-text::after{content:'';position:absolute;top:100%;left:50%;margin-left:-5px;border-width:5px;border-style:solid;border-color:#555 transparent transparent}
.share .list-item__copy:hover .copy-text.copied{visibility:visible;opacity:1}
.loader{width:300px;height:300px;margin:auto;border:2rem solid #d3d3d3;border-top:2rem solid #0065a4;border-radius:50%;-webkit-animation:spin 1s linear infinite;animation:spin 1s linear infinite}
@-webkit-keyframes spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
@keyframes spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
.statistic{color:#666;font-family:expo-serif-pro,"Palatino Linotype",Georgia,serif;font-size:calc( 18px + 2 * ((100vw - 420px) / 480));line-height:1.3}
@media screen and (max-width: 420px){.statistic{font-size:18px;font-size:1.125rem}}
@media screen and (min-width: 900px){.statistic{font-size:20px;font-size:1.25rem}}
.statistic__value{color:#0065a4;font-family:"Source Sans Pro",Helvetica,Arial,sans-serif;font-weight:300;line-height:1;font-size:calc( 58px + 4 * ((100vw - 420px) / 480))}
@media screen and (max-width: 420px){.statistic__value{font-size:58px;font-size:3.625rem}}
@media screen and (min-width: 900px){.statistic__value{font-size:62px;font-size:3.875rem}}
.statistic__description{display:block}
.site-footer{-ms-flex-wrap:wrap;flex-wrap:wrap;padding:2rem 0 3.5rem}
.site-footer::before{position:relative;top:-2rem;display:block;-ms-flex-preferred-size:100vw;flex-basis:100vw;-ms-grid-column-span:1;grid-column-end:span 1;-ms-grid-column:1;grid-column-start:1;border-top:2px solid #666}
@media screen and (min-width: 48em){.site-footer::before{top:-3rem}}
main:not(.homepage)+.site-footer::before{content:''}
@media screen and (max-width: 48em){main.homepage+.site-footer::before{margin:0 -20px;content:''}}
@media screen and (min-width: 48em) and (max-width: 63em){main.homepage+.site-footer::before{margin:0 -4vw;content:''}}
@media screen and (min-width: 48em){.site-footer{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding:2.5rem 0 3.5rem}}
@media screen and (min-width: 48em){.site-footer__info{-ms-flex-preferred-size:40%;flex-basis:40%}}
@media screen and (min-width: 63em){.site-footer__info{-ms-flex-preferred-size:30%;flex-basis:30%}}
.site-footer__info .csis-logo{max-width:200px}
@media screen and (min-width: 48em){.site-footer__info .csis-logo{max-width:300px}}
@media screen and (min-width: 63em){.site-footer__info .csis-logo{max-width:400px}}
.site-footer__info address{margin:0.75rem 0}
.site-footer__info__copyright{margin:0 0 1rem;color:#999}
.site-footer__info__copyright p{font-size:14px;font-size:.875rem;line-height:1.3}
@media screen and (min-width: 48em){.site-footer__details{-ms-flex-preferred-size:50%;flex-basis:50%}}
@media screen and (min-width: 63em){.site-footer__details{-ms-flex-preferred-size:calc(70% - 2.5rem);flex-basis:calc(70% - 2.5rem)}}
@media screen and (min-width: 90em){.site-footer__details{-ms-flex-preferred-size:45%;flex-basis:45%}}
.site-footer__details-social{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin:0 0 0.5rem;list-style-type:none}
@media screen and (min-width: 48em){.site-footer__details-social{-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}}
@media screen and (min-width: 63em){.site-footer__details-social{margin:0 0 0.75rem}}
.site-footer__details-social .icon{display:block;width:2.5rem;height:2.5rem;margin:0 0 0 1rem;border:1px solid #0065a4;border-radius:50%;cursor:pointer}
.site-footer__details-social .icon:hover{background-color:#0065a4}
.site-footer__details-social .icon:hover::before{color:#fff}
.site-footer__details-social .icon:focus{padding:0 0.125rem;background-position:0 87.5%}
.site-footer__details-social .icon::before{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;height:100%;margin:0;color:#0065a4;font-size:20px;font-size:1.25rem}
@media screen and (min-width: 63em){.site-footer .site-footer__subsection{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}}
.site-footer .site-footer__subsection section{border-top:2px solid #999}
@media screen and (min-width: 63em){.site-footer .site-footer__subsection section{-ms-flex-preferred-size:calc(50% - 1.5rem);flex-basis:calc(50% - 1.5rem)}}
.site-footer .site-footer__subsection-title{margin:0.5rem 0}
.site-footer .site-footer__subsection-nav{margin-bottom:2rem}
.site-footer .site-footer__subsection-nav li{margin:0 0 0.5rem}
.site-footer .site-footer__subsection-content p:first-of-type{margin-bottom:2rem}
.site-footer .site-footer__subsection-content p:not(:first-of-type){margin:0}
.site-header{position:relative;width:100vw;background-color:#f3f3f3;border-bottom:2px solid #666;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out}
@media screen and (min-width: 0){.site-header{height:40px}}
@media screen and (min-width: 48em){.site-header{height:48px}}
@media screen and (min-width: 63em){.site-header{height:64px}}
@media screen and (min-width: 90em){.site-header{height:64px}}
.site-header--hidden{opacity:0}
.site-header--minimal{position:fixed;top:0;right:0;left:0;z-index:10}
.site-header .site-logo{display:inline-block;padding:0 0.125rem;line-height:0}
.site-header .site-logo:not(:focus){outline:2px solid transparent}
.site-header .site-logo__icon{max-height:24px}
@media screen and (min-width: 63em){.site-header .site-logo__icon{max-height:32px}}
section.row:not(#disclaimer):not(#job_alerts):not(#testimonials):not(#intern_eligibility):not(:first-of-type)::before{position:relative;top:-0.25rem;display:block;-ms-grid-column-span:3;grid-column-end:span 3;-ms-grid-column:1;grid-column-start:1;margin-bottom:12px;border-top:1px solid #666;content:''}
section.row:not(#disclaimer):not(#job_alerts):not(#testimonials){margin-bottom:3.5rem}
.header{display:-ms-grid;display:grid;-ms-grid-columns:1fr;grid-template-columns:1fr;-ms-grid-rows:1fr;grid-template-rows:1fr}
.header > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1}
@media screen and (min-width: 63em){main:not(.opportunities) .header{grid-column-gap:48px;-ms-grid-columns:1fr 48px 1fr;grid-template-columns:1fr 1fr;-ms-grid-rows:auto 1fr;grid-template-rows:auto 1fr}main:not(.opportunities) .header > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1}main:not(.opportunities) .header > *:nth-child(2){-ms-grid-row:1;-ms-grid-column:3}main:not(.opportunities) .header > *:nth-child(3){-ms-grid-row:2;-ms-grid-column:1}main:not(.opportunities) .header > *:nth-child(4){-ms-grid-row:2;-ms-grid-column:3}}
@media screen and (min-width: 90em){main:not(.opportunities) .header{grid-column-gap:72px}}
.header__intro{padding-top:3rem;background-color:#f3f3f3;position:relative}
.header__intro::before,.header__intro::after{position:absolute;top:0;z-index:-1;display:block;width:calc((100vw - 100%) / 2);height:100%;background-color:#f3f3f3;content:''}
.header__intro::before{right:100%}
.header__intro::after{left:100%}
@media screen and (min-width: 63em){.header__intro{-ms-grid-row:1;-ms-grid-row-span:1;-ms-grid-column:1;-ms-grid-column-span:1;grid-area:1 / 1 / 2 / 2}.header__intro::after{width:calc((100vw - 200% - 72px) / 2 + 100% + 72px)}}
.header__text{-ms-grid-row:3;-ms-grid-row-span:1;-ms-grid-column:1;-ms-grid-column-span:1;grid-area:3 / 1 / 4 / 2}
@media screen and (min-width: 63em){.header__text{-ms-grid-row:2;-ms-grid-row-span:1;-ms-grid-column:1;-ms-grid-column-span:1;grid-area:2 / 1 / 3 / 2;padding-top:2rem}}
@media screen and (min-width: 63em){.header__image{-ms-grid-row:1;-ms-grid-column:2;grid-area:1 / 2 / 3 / 2;width:calc(100% + 4vw);padding-top:3rem;-ms-grid-row-span:2}}
@media screen and (min-width: 63em){.cantilever{display:-ms-grid;display:grid;grid-column-gap:48px;-ms-grid-columns:calc((((100% - 288px) / 15) * 4) + 72px) 48px auto;grid-template-columns:calc((((100% - 288px) / 15) * 4) + 72px) auto;-ms-grid-rows:1fr;grid-template-rows:1fr}.cantilever > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1}.cantilever > *:nth-child(2){-ms-grid-row:1;-ms-grid-column:3}}
@media screen and (min-width: 90em){.cantilever{grid-column-gap:72px}}
@media screen and (min-width: 63em){main:not(.faq) .subsection:not(.header){display:-ms-grid;display:grid;grid-column-gap:48px;-ms-grid-columns:1fr 48px 1fr;grid-template-columns:1fr 1fr;-ms-grid-rows:1fr auto [last-line];grid-template-rows:1fr auto [last-line]}main:not(.faq) .subsection:not(.header) > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1}main:not(.faq) .subsection:not(.header) > *:nth-child(2){-ms-grid-row:1;-ms-grid-column:3}main:not(.faq) .subsection:not(.header) > *:nth-child(3){-ms-grid-row:2;-ms-grid-column:1}main:not(.faq) .subsection:not(.header) > *:nth-child(4){-ms-grid-row:2;-ms-grid-column:3}main:not(.faq) .subsection:not(.header) > *:nth-child(5){-ms-grid-row:3;-ms-grid-column:1}main:not(.faq) .subsection:not(.header) > *:nth-child(6){-ms-grid-row:3;-ms-grid-column:3}}
@media screen and (min-width: 90em){main:not(.faq) .subsection:not(.header){grid-column-gap:72px}}
main:not(.faq) .subsection:not(.header) div:nth-child(odd):last-of-type{-ms-grid-column-span:3;grid-column-end:span 2;-ms-grid-column:1;grid-column-start:1;-ms-grid-row:last-line;grid-row-start:last-line}
@media screen and (min-width: 63em){.columns{display:-ms-grid;display:grid;grid-row-gap:1.5rem;-ms-grid-columns:1fr 1fr;grid-template-columns:1fr 1fr;-ms-grid-rows:1fr;grid-template-rows:1fr}.columns > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1}.columns > *:nth-child(2){-ms-grid-row:1;-ms-grid-column:2}}
.job-post__content{min-height:100vh}
.homepage #disclaimer{position:relative;margin:0 auto;padding:2.5rem 0 4rem;color:#fff;background-color:#0065a4}
.homepage #disclaimer::before,.homepage #disclaimer::after{position:absolute;top:0;z-index:-1;display:block;width:calc((100vw - 100%) / 2);height:100%;background-color:#0065a4;content:''}
.homepage #disclaimer::before{right:100%}
.homepage #disclaimer::after{left:100%}
.opportunities #job_alerts{position:relative;margin:0 auto;padding:2.5rem 0 4rem;background-color:#f3f3f3}
.opportunities #job_alerts::before,.opportunities #job_alerts::after{position:absolute;top:0;z-index:-1;display:block;width:calc((100vw - 100%) / 2);height:100%;background-color:#f3f3f3;content:''}
.opportunities #job_alerts::before{right:100%}
.opportunities #job_alerts::after{left:100%}
.internships #testimonials{position:relative;margin:0 auto;margin-bottom:1rem;padding:2.5rem 0 4rem;background-color:#f3f3f3}
.internships #testimonials::before,.internships #testimonials::after{position:absolute;top:0;z-index:-1;display:block;width:calc((100vw - 100%) / 2);height:100%;background-color:#f3f3f3;content:''}
.internships #testimonials::before{right:100%}
.internships #testimonials::after{left:100%}
@media screen and (max-width: 48em){.faq .header li:not(:last-of-type){margin-right:2rem}}
@media screen and (min-width: 48em) and (max-width: 63em){.faq .header li:not(:last-of-type){margin-right:3rem}}
@media screen and (max-width: 63em){.faq .header ul{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap}}
.faq div[class^='cantilever__']:not(:last-of-type){margin-bottom:24px;padding-bottom:0.5rem;border-bottom:1px solid #d3d3d3}
.faq div[class^='cantilever__'] p:first-of-type{margin-bottom:4px}
#listings{margin-top:2rem;margin-bottom:2.5rem}
@media screen and (min-width: 63em){#listings{display:-ms-grid;display:grid;grid-column-gap:4vw;-ms-grid-columns:calc((((100% - 288px) / 12) * 4) + 72px) 4vw auto;grid-template-columns:calc((((100% - 288px) / 12) * 4) + 72px) auto;-ms-grid-rows:1fr;grid-template-rows:1fr}#listings > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1}#listings > *:nth-child(2){-ms-grid-row:1;-ms-grid-column:3}}
#filter-trigger{position:absolute;opacity:0}
@media screen and (max-width: 63em){#filter-trigger ~ ul,#filter-trigger ~ button{height:0;min-height:0;overflow:hidden;background-color:#fff;opacity:0;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out}#filter-trigger ~ button{position:absolute}.listings__filters-category #filter-trigger:checked ~ .filters-label::after{-webkit-transform:rotate(0deg);transform:rotate(0deg)}.listings__filters-category #filter-trigger:checked ~ ul{height:auto;min-height:100px;opacity:1;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out}.listings__filters-category #filter-trigger:checked ~ button{position:relative;height:auto;opacity:1}}
.listings__filters{padding-top:0.5rem}
@media screen and (max-width: 63em){.listings__filters{padding-bottom:1.5rem;border-bottom:solid 1px #999}}
@media screen and (min-width: 63em){.listings__filters{padding-right:2.5rem;border-right:solid 1px #999}}
@media screen and (max-width: 63em){.listings__filters-category .filters-label{cursor:pointer}}
@media screen and (max-width: 63em){.listings__filters-category .filters-label::after{margin-left:0.25rem;content:'\e811';-webkit-transform:rotate(180deg);transform:rotate(180deg)}}
@media screen and (min-width: 63em){.listings__filters-summary{display:none}}
.listings__filters-search-box{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;height:2.6rem;margin-bottom:2rem;border:solid 1px #999}
.listings__filters-search-box::before{position:absolute;top:33%;margin:0 0.5rem 0 1rem;font-weight:bold;content:'\e806'}
.listings__filters-search-box input{width:100%;padding:0 0.75rem 0 2.5rem}
.listings__filters-category_list label{display:inline-block;line-height:1.56;cursor:pointer}
.listings__filters-category_list label::before{margin-right:0.5rem;content:'\e813'}
.listings__filters-category_list label.checked::before{content:'\e812'}
.listings__results-list{min-height:350px;padding-top:3rem}
.listings__results-list .job{padding-bottom:2rem}
.listings__results-list .job:not(:first-of-type){padding-top:1.5rem;border-top:1px solid #d3d3d3}
@media screen and (min-width: 48em){.listings__results-list .job:not(:first-of-type){padding-top:1.8rem}}
main.post .job-header{margin-bottom:2rem;padding:2.5rem 0 2rem;background-color:#f3f3f3;position:relative;margin:0 auto}
main.post .job-header::before,main.post .job-header::after{position:absolute;top:0;z-index:-1;display:block;width:calc((100vw - 100%) / 2);height:100%;background-color:#f3f3f3;content:''}
main.post .job-header::before{right:100%}
main.post .job-header::after{left:100%}
@media screen and (min-width: 48em){main.post .job-header{padding-bottom:2rem}}
main.post .job-content{margin:2.5rem auto 5.5rem}
@media screen and (min-width: 48em){main.post .job-content{display:-ms-grid;display:grid;grid-column-gap:88px;-ms-grid-columns:minmax(240px, calc(1200px - 88px - 375px)) 88px minmax(240px, 375px);grid-template-columns:minmax(240px, calc(1200px - 88px - 375px)) minmax(240px, 375px);-ms-grid-rows:1fr;grid-template-rows:1fr;max-width:1200px;margin-top:3rem}main.post .job-content > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1}main.post .job-content > *:nth-child(2){-ms-grid-row:1;-ms-grid-column:3}}
main.post .job-content__body{position:relative;min-height:350px;overflow:hidden}
main.post .job-content__redirect{font-size:16px;font-size:1rem;color:rgba(0,0,0,0.7);line-height:1.35}
main.post aside img{max-width:280px;margin-bottom:1rem}
main.post .job-post__section{margin-bottom:4.5rem;padding-top:0.5rem}
main.post .job-post__section:not(:first-of-type)::before{position:relative;top:-0.5rem;display:block;-ms-grid-column-span:1;grid-column-end:span 1;-ms-grid-column:1;grid-column-start:1;border-top:1px solid #666;content:''}
@font-face{font-family:'careerportal';font-weight:normal;font-style:normal;font-display:swap;src:url("../fonts/careerportal.eot?98056089");src:url("../fonts/careerportal.eot?98056089#iefix") format("embedded-opentype"),url("../fonts/careerportal.woff2?98056089") format("woff2"),url("../fonts/careerportal.woff?98056089") format("woff"),url("../fonts/careerportal.ttf?98056089") format("truetype"),url("../fonts/careerportal.svg?98056089#careerportal") format("svg")}
[class^='icon-']::before,[class*=' icon-']::before{display:inline-block;width:1em;margin-right:0.2em;margin-left:0.2em;font-family:'careerportal';font-weight:normal;font-style:normal;-webkit-font-feature-settings:normal;font-feature-settings:normal;font-variant:normal;line-height:1em;text-align:center;text-transform:none;text-decoration:inherit;speak:none;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
.icon-clear::before{content:'\e800'}
.icon-close::before{content:'\e801'}
.icon-link-external::before{content:'\e802'}
.icon-share::before{content:'\e803'}
.icon-email::before{content:'\e804'}
.icon-arrow-back::before{content:'\e805'}
.icon-search::before{content:'\e806'}
.icon-arrow-forward::before{content:'\e807'}
.icon-instagram::before{content:'\e808'}
.icon-linkedin::before{content:'\e809'}
.icon-youtube::before{content:'\e80a'}
.icon-twitter::before{content:'\e80b'}
.icon-facebook::before{content:'\e80c'}
.icon-profile-avatar::before{content:'\e80d'}
.icon-printer::before{content:'\e80e'}
.icon-copy-link::before{content:'\e80f'}
.icon-back-to-top::before{content:'\e810'}
.icon-arrow-down::before{content:'\e811'}
.icon-checkbox-checked::before{content:'\e812'}
.icon-checkbox-base::before{content:'\e813'}
